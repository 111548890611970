@mixin modal-padding {
  padding: 16px 22px;;
}

.ant-modal {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.ant-modal-header {
  @include modal-padding;
}

.ant-modal-body {
  max-height: calc(90vh - 48px - 72px);
  overflow: auto;
}

.ant-modal-footer {
  @include modal-padding;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(13, 23, 42, 0.08), inset 0px 1px 0px rgba(13, 23, 42, 0.08);
}

.ant-modal-header {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #0D172A;
  opacity: 0.8;
  position: relative;
  background-color: #F8F9F9;
  &.with-title{
    box-shadow: inset 0px -1px 0px rgba(13, 23, 42, 0.08);
  }
}

.ant-modal-close {
  color: #0D172A;
  opacity: 0.8;
  position: absolute;
  top: 16px;
  right: 22px;
  cursor: pointer;
  z-index: 1;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ant-modal {
    height: auto !important;
    width: 100%;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
  .ant-modal {
    height: 100%;
    width: 100%;
  }
  .ant-modal-close {
    top: 12px;
    svg {
      font-size: 25px;
    }
  }
  .ant-modal-body {
    max-height: calc(90vh - 75px);
  }

}
