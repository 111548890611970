.incoming-call-chat-header {
    border-radius: 0 !important;
}
.incoming-call-chat {
    background-color: white;
    display: grid;
    grid-template-rows: 8vh 0vh 8vh 73vh;
    height: 90vh;
    .disabledSenderIcon {
        pointer-events: none;
        color: #bcbcbc;
    }
    .media-message-wrapper {
        display: flex;
    }
    .download-link {
        margin-top: 20%;
        margin-left: 15px;

        span {
            font-size: 25px;
            color: lightseagreen;
        }
    }
    .rcw-messages-container {
        max-height: none;
        height: auto;
    }
    .chat-bubble{
        color: #3c3c3c!important;
        background-color: #eaeaea !important;
        padding: 10px;
        border-radius: 5px;
        margin-right: 10px;
        img {
            max-width: 190px;
            max-height: 140px;
            border-radius: 5px;
            object-fit: cover;
        }
        p, span {
            white-space: pre-line;
        }
    }
    .call-time-wrap{
        background-color: #253342;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        .phone-icon{
            background-color: #515C68;
            border-radius: 25px;
            width: 25px;
            height: 25px;
            padding: 9px;;
        }
        .call-time {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            font-size: 15px;
            color: #d9d9d9;
        }
    }
    .chat-main-body {
        position: relative;
        background-color: #F8F9F9;
        .chat-body {
            margin: 10px -35px 0 12px;
            position: absolute;
            overflow-y: scroll;
            padding: 10px;
            width: 25vw;
            background-color: #F8F9F9;
            height: auto;

            .type-doc {
                margin-top: 5px;
                margin-right: 10px;
            }
        }
        .chat-footer {
            position: absolute;
            bottom: 0;
            height: 65px;
            display: flex;
            align-items: center;
            border-top: 2px solid #e9e9e9;
            background-color: white;
            .msg-form {
                width: 95%;
                height: 30px;
                .msg-input {
                    width: 100%;
                    height: 30px!important;
                    resize: none;
                    border: none;
                }
            }
            .tab-container {
                padding: 0;
            }
            .sender-wrap {
                width: 25vw;
                height: 8vh;
                display: flex;
                align-items: center;
                .mood-icon {
                    color: #727272;
                    cursor: pointer;
                }
                .attachment-icon {
                    cursor: pointer;
                    margin-left: 10px;
                    color: #727272;
                }
                input {
                    border: none;
                    box-shadow: none;
                }
            }
            .sender-controls {
                width: 60px;
                padding-top: 0!important;
            }
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 13px 0;
    background-color: #3A1C1C;
    color: white;
    font-size: 15px;
    align-items: center;
    width: 25vw;
    .header-title-wrap {
        margin: 0 auto;
    }
}

.store-location {
    font-size: 13px;
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    margin-top: -7px;
    padding-left: 5px;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .header {
    width: 100%;
  }
  .incoming-call-chat {
    .chat-main-body {
      .chat-body {
        .flex-nowrap:last-child {
          margin-bottom: 100px !important;
        }
        width: 100%;
      }
      .chat-footer {
        width: 100%;
        .sender-wrap {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.chat-wrapper {
    width: 75%;
    .chat-bubble-wrap {
        margin-right: 10px;
        max-width: 80%;
        .chat-bubble-timer-container{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .chat-bubble-type{
                background: #D8DADD;
                font-size: 10px;
                padding: 1px 5px;
                border-radius: 4px;
                i{
                    margin-right: 5px;
                }
            }
            .time{
                display: flex;
                justify-content: space-between;
                align-items: center;
                i{
                    color: #D8392F;
                }
            }
        }
    }
    .chat-main-body {
        .chat-head {
            padding: 0.625rem 1.5rem;
            @media(max-width:450px){
                padding:0.75rem;
                .video-icon{
                    display: none;
                }
            }
            border: 1px solid #ECECEE;
            background-color: white;
            .chat-head-controls {
                display: flex;
                justify-content: flex-end;
                .assign-conversation-button {
                    display: flex;
                    justify-content: space-between;
                    background-color: #FF7A59;
                    width: 135px;
                    color: white;
                    padding: 10px;
                    font-size: 15px;
                    &.disabled {
                        color: black;
                        pointer-events: none;
                        background-color: #D8DADD;
                        width: 115px;
                    }
                }
                .resolve-conversation-button {
                    display: flex;
                    justify-content: space-between;
                    background-color: #1BC47D;
                    color: white;
                    padding: 10px;
                    font-size: 15px;
                    width: 95px;
                    margin-left: 15px;
                    margin-right: 10px;
                    &.disabled {
                        background-color: unset;
                        pointer-events: none;
                        border: 1px solid #606060;
                        color: #606060;
                    }
                    &.resolved {
                        width: 115px;
                    }
                }
            }
            button {
                height: 2.25rem;
                width: 2.25rem;
                font-size: 1.25rem;
                margin: 0 0.25rem;
            }
        }
        .chat-body {
            margin-top: 15px;
            margin-left: 10px;
            .chat-bubble {
                padding: 0.625rem;
                box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
                display: flex;
                align-items: center;
                position: relative;
                overflow-wrap: anywhere;
                min-width: 50px;
                p {
                    white-space: pre-line;
                }
                &.even {
                    background-color: white;
                    border-radius: 4px;
                }
                &.odd {
                    border-radius: 4px;
                    background-color: #d8dadd;
                    margin-right: 0px;
                    margin-left: auto;
                }

                .type-media {
                    position: relative;
                    max-width: 200px;
                    max-height: 150px;
                    display: inline-block;
                    overflow: hidden;
                    vertical-align: top;

                    .type-media-video {
                        position: relative;
                        video {
                            width: 200px;
                            height: 125px;
                        }
                    }
                    img {
                        max-width: 190px;
                        max-height: 140px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                }
            }
            .type-doc {
                padding-top: 10px;
                a {
                    color: #017AFF;
                    white-space: nowrap;
                    max-width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .survey-header {
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                border-top-left-radius: 8px !important;
                border-top-right-radius: 8px !important;
                background-color: #F3F3F3 !important;
                box-shadow: none;
            }
            .survey-container {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                margin-bottom: 1rem;
            }
            .survey-icon-block {
                background-color: #D83A2F;
            }
            .survey-icon {
                font-size: 25px;
                color: #fff;
            }
            .survey-item {
                border-radius: 0 !important;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
            .survey-wrapper {
                width: 50%;
            }
            .chat-bubble.even.bg-aqua.survey-item:last-child {
                border-bottom-left-radius: 8px !important;
                border-bottom-right-radius: 8px !important;
            }
            .chat-bubble.even.bg-aqua.survey-item:not(:last-child) {
                margin-bottom: 2px;
            }
            .survey-title {
                font-weight: 700;
                width: 100%;
            }
            .emoji-wrapper {
                margin-top: 15px;
                .disabled-button {
                    color: rgba(16, 16, 16, 0.3);
                    border-color: rgba(16, 16, 16, 0.3);
                }
                .emoji-button {
                    border-width: 1px;
                    width: 19%;
                    margin-right: 1%;
                    height: 35px;
                    border-radius: 7px;
                    .emoji-item {
                        font-size: 24px;
                    }
                }
            }
        }

        .chat-footer {
            position: fixed;
            width: 100%;
            bottom: -45px;
            .sender-controls {
                min-width: 50px;

                &:hover {
                    cursor: pointer;
                }
            }
            .msg-form {
                width: 95%;
                position: relative;
            }
            .msg-input {
                border: 0;
                padding: 7px 10px 55px 15px;
                box-shadow: inset 0 0 0 #ddd;
                width: 95%;
                &:focus {
                    box-shadow: inset 0 0 0 #ddd;
                    //border: 0;
                }
            }
            .submit-btn {
                //border-radius: 100px !important;
            }
        }
    }
    .chat-content {
        position: relative;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        .chat-box-main {
            position: absolute;
            top: 0;
            bottom: 10rem;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .call-ended-message {
        margin: 20px 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 140px;
        &.call-declined {
            width: 115px;
        }
        span {
            font-size: 15px;
        }
    }
}
.disabledSenderIcon{
    pointer-events: none;
    color: #bcbcbc;
}
.chat-sidebar {
    display: flex;
    .users-list-wrapper {
        width: 300px;
    }
    .user-wrap {
        height: auto;
    }
    .search-wrapper {
        .search-icon {
            position: absolute;
            right: 1rem;
            top: 0.9rem;
        }
    }
    .chat-list {
        ul {
            .user-list-item {
                color: #464D69;
                cursor: pointer;
                padding: 1rem;
                border-bottom: 2px solid #F8F9F9;
                transition: all 0.3s ease-in-out;

                .status-active-customer {
                    position: absolute;
                    top: 70%;
                    left: 70%;
                    padding: 4px;
                }

                .media-left {
                    .badge {
                        position: absolute;
                        bottom: 0px;
                    }
                }
                .msg-count {
                    .badge {
                        height: 1.5rem;
                        width: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                    }
                }
                &.item-active {
                    background-color: #f8f4ee;
                    border-left: 3px solid #FF7A59;
                }
                &:hover {
                    &:not(.item-active) {
                        //background-color: $aqua;
                    }
                }
            }
            .user-list-item:nth-last-child(1) {
                padding-bottom: 100px;
            }
        }
    }
}
.rct-scroll {
    height: calc(100% - 65px)!important;
}
.agent-message {
    display: flex;
    margin-top: 10px;
}
.user-message {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    margin-right: 10px;
}
.message-text {
    margin: 0;
}
.text-right {
    text-align: left !important;
}
.chat-bubble-wrap {
    margin-right: 10px;
    max-width: 80%;
    .chat-bubble-timer-container{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        .chat-bubble-type{
            background: #D8DADD;
            font-size: 10px;
            padding: 1px 5px;
            border-radius: 4px;
            i{
                margin-right: 5px;
            }
        }
        .text-right{
            text-align: right!important;
        }
        .time{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            i{
                color: #D8392F;
                margin-left: 5px;
            }
        }
    }
}
.text-right{
    text-align: right!important;
}
.incoming-call-chat {
    .msg-form {
        width: 35%;
        input {
            width: 320px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .chat-bubble-wrap {
        max-width: 290px;
        overflow: hidden;
    }
}
.msg-input {
    width: 100%;
    height: 30px!important;
    resize: none;
    border: none;
    outline: none;
    font-family: Roboto,Helvetica,Arial,Heebo,sans-serif;
    font-weight: 500;
    font-size: 16px;
    &:focus {
        border: none;
    }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
    .chat-head {
        .chat-head-controls {
            justify-content: flex-start !important;
            .right-button {
                position: absolute;
                right: 10px;
            }
        }
    }
    .chat-footer {
        .msg-input {
            width: 84%!important;
        }
        .submit-button {
            position: absolute;
            height: 20px;
            top: 10px;
            right: 20px;
            color: #1BC47D;
        }
        .mobile-channels-box{
            display: flex;
            flex-direction: row;
        }
        .btn-in-use {
            color: rgb(93, 146, 244) !important;
        }
    }
}

@media only screen and (max-width: 375px) {
    .chat-bubble{
        max-width: 300px!important;
    }
}
