.video-wrapper {
  height: 90vh;
  position: relative;
  width: 100%;
  &.with-chat {
    height: 100vh;
    position: relative
  }
}
.hidden {
  display: none;
}
.MuiPopover-root {
  z-index: 9999!important;
}
.organization-logo {
  object-fit: cover;
  height: 100px;
}
.call-room-base {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
}
.user-stream {
  position: absolute;
  top: 0;
  right: 0;
  width: 17%;
  &.isDisabled {
    display: none;
  }
}

.video-call {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &.isDisabled {
    display: none;
  }
}

.call-room-content {
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.control-button {
  background-color: red;
  color: black;
  &:hover {
    background-color: #c8c8c8;
  }
  &.active{
    border: 1px solid #888888;
    span {
      color: #888888;
    }
  }
}

.video-container{
  width: 100%;
  position: relative;
  display: grid;

  &.with-chat{
    top: 0;
    display: flex !important;

    .video-wrapper {
      width: 75%;
    }

    .incoming-call-chat {
      width: 25%;
    }

    .video-call {
      min-width: 100%;
    }

    .user-stream {
      display: none;
    }
  }
}
.waiting-screen-error {
  color: white;
  font-size: 25px;
  position: relative;
  top: 40%;
  text-align: center;
}
.close-modal-button {
  border-radius: 30px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: #ececec;
  width: 65px;
  height: 65px;
  margin: 15px;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    background-color: #d7d7d7;
  }
}

.video-space-wrapper {
  .main-wrapper{
    height: 90vh;
    background-color: #0D172A;
    .size-avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
  .toolbar {
    height: 10vh;
    position: absolute;
    background-color: white;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between!important;
    .list-products {}
    .toolbar-controls {
      width: 12%;
      justify-content: space-between!important;
    }
    .toolbar-chat {
      margin-right: 25px;
    }
  }
}
.call-ended-wrapper {
  margin-top: 20%;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  .callback-button {
    width: 10%;
    margin: 10px auto;
  }
}
.waiting-room-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .name-input {
    margin: 10px;
  }
  .waiting-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;

    .title-question {
      font-size: 17px;
    }

    .settings-screen {
      position: relative;
      background-color: #253342;
      height: 50vh;
      width: 100%;
    }

    .settings-screen-camera {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .video-preview {
        width: 100%    !important;
        height: 100%   !important;
      }
    }
    .settings-controls {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 10% !important;
      width: 100% !important;
      button {
        height: 55px;
        border-radius: 30px;
        margin-left: 15px;
        .button-text {
          margin-left: 10px;
          text-decoration: none;
        }
      }
    }

    .customer-section {
      padding-top: 40px;

      .customer-avatar {
        display: flex;
        justify-content: center;
        .large-size {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin: 0 !important;
        }
      }

      .customer-activity {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 500;
      }

      .join-button-wrapper {
        padding-top: 30px;
        display: flex;
        justify-content: center;
      }
      .agent-busy-text {
        margin-bottom: 5px;
      }
    }
  }
}

.btn-join-now {
  background: #d8392f;
  color: white;
  padding: 0.8rem 2rem !important;
  font-weight: bold !important;
  border: none !important;
  font-size: 0.8rem !important;
  border-radius: 5px;
  &:hover {
    background: #ff0000;
    color: white;
  }
  &.disabled {
    background: #ff8585;
    pointer-events: none;
    cursor: unset;
  }
}

.name-input-wrapper {
  display: flex;
  align-items: baseline;
}

.video-space-wrapper {
  .main-container {
    position: relative;
    height: 90vh;
    background-color: #0D172A;
    display: flex;
    justify-content: center;
    align-items: center;
    .size-avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .local-video-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      height: 150px;

      video {
        width: 200px !important;
        height: 150px !important;
      }
    }
  }

  .toolbar {
    height: 10vh;
    z-index: 1;

    .toolbar-controls {
      width: 25%;
      margin: 0 auto;
    }

  }
}



.video-space-wrapper-mobile {
  position: relative;
  height: 100vh !important;
  .main-wrapper{
    height: 100vh !important;
  }
  .local-video {
    left: 10px !important;
    right: auto !important;
    top: 105px !important;
    object-fit: cover !important;
    object-position: center center !important;
    height: 170px !important;
    width: 100px !important;
  }

  .remote-video {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    object-fit: cover !important;
    object-position: center center !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 0 !important;
  }

  .room-call-header {
    position: absolute;
    left: 0;
    top: 0;
    height: 95px;
    width: 100%;
    z-index: 3;
    overflow: hidden;

    &::before {
      position: absolute;
      background: #33475B;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
      opacity: 0.3;
    }

    &.is-blurred::before {
      position: absolute;
      background: #253342;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
      opacity: 0.3;
    }

    .ti-arrow-left {
      position: absolute;
      bottom: 0;
      left: 0;
      color: white;
      padding: 10px
    }
  }

  .camera-disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #253342;
    z-index: 1;

    >div {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      margin-top: -80px;
      width: 100%;

      >img {
        display: block;
        margin: 20px auto;
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
      span {
        margin: 0 5px;
        color: #888888;
        position: relative;
        top: 7px;
      }

      >b {
        color: white;
        margin-right: 10px;
      }
    }
  }

  .video-wrapper {
    height: 100vh !important;
    width: 100% !important;
  }

  .incoming-call-chat {
    width: 100%;
    height: calc(100% - 82px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    overflow: hidden;

    .header, .header-title-wrap {
      width: 100%;
      padding: 10px;
    }

    .header {
      height: 55px;
    }

    .store-location {
      height: 30px;
    }

    .call-time-wrap {
      height: 65px;
    }

    .chat-body, .sender-wrap {
      margin: 0!important;
      width: 100%!important;
      overflow-x: hidden;

      .msg-form {
        width: 100%;
        input[type="text"] {
          width: 70%;
        }
      }
    }

    .chat-main-body {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - 150px)!important;

      .rct-scroll {
        height: calc(100% - 65px)!important;
      }

      .chat-footer {
        width: 100%;
        display: block;

        .sender-controls {
          width: 40px;
        }
        .submit-button {
          top: 37%;
        }
      }
    }
  }

  .main-container {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #253342;

    .video-container {
      height: 100%;
    }
  }

  .toolbar {
    background: transparent;
    border: 0!important;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    height: 82px;
    z-index: 2;

    &::before {
      position: absolute;
      background: #253342;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
      opacity: 0.3;
    }
  }

  .call-controls-wrapper {
    position: absolute;
    left: 0;
    top: 15px;
    width: 100%;
    text-align: center;
    display: block;

    button span {
      color: white;
    }

    .call-buttons-wrapper {
      display: inline-block;
    }

    .settings-button, .end-call-button {
      margin-left: 15px;
      box-shadow: unset;
      border: 1px solid #D8DADD;
      width: initial;
    }

    .settings-button {
      margin: 0;
      width: initial;
    }

    .end-call-button {
      border-color: #ea4141;

      span {
        color: #ea4141;
      }
    }

    .controls-divider {
      margin-right: 0;
      margin-left: 15px;
      display: inline-block;
      margin-bottom: -20px;
      background-color: #33475B;
      opacity: 0.6;
    }

  }
  .video-call{
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    object-fit: cover !important;
    object-position: center center !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 0 !important;
  }
  .video-container{
    height: 100vh !important;
    width: 100vw !important;
  }
  .user-stream{
    left: 10px !important;
    right: auto !important;
    top: 105px !important;
    object-fit: cover !important;
    object-position: center center !important;
    height: 170px !important;
    width: 100px !important;
  }
  .call-buttons-wrapper {
    padding: 0 !important;
    button{
      margin-left: 15px;
    }
  }
  .video-container.with-chat {
    .incoming-call-chat{
      width: 100% !important;
    }
  }
}


@media only screen and (max-width: 640px) {
  .video-space-wrapper {
    .main-wrapper {
      height: 83vh;
    }
  }
  .video-container {
    &.with-chat{
      position: relative;
    }
    grid-template-columns: auto !important;
  }
  .waiting-room-wrapper {
    .waiting-screen {
      width: 100%;
    }
  }

  .video-container{
    &.with-chat{
      .incoming-call-chat{
        width: 100% !important;
        .senderWrap{
          bottom: 25px !important;
        }
        .call-time{
          font-size: 12px !important;
        }
        .call-time-wrap {
          z-index: 1 !important;
        .phone-icon{
          width: 30px !important;
          height: 30px !important;
          margin: 0 !important;
        }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .call-buttons-wrapper {
    button{
      margin-left: 5px !important;
    }
  }
}

@media only screen and (max-width: 280px) {
  .controls-divider{
    margin-left: 5px !important;
  }
  .end-call-button{
    margin-left: 5px !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1280px) {
  .android-tablet-device {
    .main-container {
      position: relative;
    }

    .video-space-wrapper .main-wrapper {
      align-items: flex-start !important;

      .incoming-call-chat {
        width: 100% !important;
        grid-template-rows: 14vh 3vh 7vh 48vh 10vh;

        .senderWrap {
          position: relative;
          bottom: 0;
          top: 0;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ios-tablet-device .video-space-wrapper .main-wrapper {
    align-items: flex-start !important;
    .incoming-call-chat {
      grid-template-rows: 14vh 3vh 7vh 52vh 10vh;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ios-tablet-device .video-container.with-chat .incoming-call-chat {
    width: 100% !important;
  }
}

