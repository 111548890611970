 .settings-modal-body {
    display: flex;
    height: 65vh;
    width: 70vw;
    max-width: 700px;
    max-height: 500px;
    min-height: 450px;
    z-index: 9999;
    background-color: white;

    .left-side {
      background-color: #253342;
      width: 35%;
      display: flex;
      flex-direction: column;
      h2 {
        color: white;
        height: 55px;
        padding: 20px;
        text-align: left;
      }
    }

    .right-side {
      width: 65%;
    }
    .button {
      border-radius: 0;
      color: #d3d6d9;
      height: 55px;
      font-size: 16px;
      width: 100%;
      span {
        width: 100%;
        text-align: left;
        margin-left: 15px;
        display: block;
        text-transform: none;
      }
      i {
        margin-right: 10px;
      }
    }
    .active {
      background-color: #33475b;
      border-left: 1px solid #ff7a59;
      color: white;
      i {
        color: #ff7a59;
      }
    }
    .audio {
      display: flex;
      padding: 15px;
      flex-direction: column;

      .settings-block {
        display: flex;
        margin-bottom: 20px;

        .control {
          display: flex;
          width: 20%;
          border-radius: 15px;
          align-items: center;
          margin-top: 21px;
          position: relative;

          .sound-test-button {
            font-size: 15px;
            margin-left: 5px;
            height: 35px;
            padding-left: 5px;
            border-radius: 5px;
            .button-text {
              padding-left: 10px;
            }
            &:hover {
              background-color: #f1f1f1;
            }
          }
        }
        .sound-icon {
          margin-right: 5px;
          margin-left: 3px;
        }
        .microphone-icon {
          margin-right: 10px;
          margin-left: 5px;
          height: 35px;
          padding-left: 5px;
          border-radius: 5px;
          .button-text {
            padding-left: 10px;
          }
          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
      .sound-icon {
        margin-right: 5px;
        margin-left: 3px;
      }
      .microphone-icon {
        margin-right: 10px;
        margin-left: 5px;
      }
      .volume-wrapper {
        height: 5px;
        background-color: #d9d9d9;
        width: 150px !important;
        border-radius: 15px;
        .volume {
          height: 5px;
          background-color: #d9d9d9;
          width: 150px !important;
          border-radius: 15px;
          .volume {
            height: 5px;
            background-color: #ff7a59;
            max-width: 55px;
            border-radius: 15px;
          }
        }
      }
    }
    .video-block {
      display: flex;
      padding: 15px;
      flex-direction: column;
    }
    video {
      width: 160px;
      height: 120px;
      border-radius: 6px;
      margin-top: 15px;
      background-color: black;
    }
    .settings {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
    }
  }

@media only screen and (max-width: 540px) and (orientation: portrait) {
  .settings-modal-body{
    height: 100vh;
    width: 100vw;
    background-color: white;

    .right-side{
      overflow: auto;
    }
  }
}
