.online-dot {
  background-color: #1bc47d;
  border-radius: 25px;
  position: absolute;
  height: 14px;
  width: 14px;
  display: block;
  margin-top: 25px;
  margin-left: 25px;
  border: 3px solid white;
  &.md {
    height: 17px;
    width: 17px;
    margin-left: 30px;
    margin-top: 30px;
  }
}
.avatar {
  background-color: #f1f1f1;
  border-radius: 25px;
  position: absolute;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.md {
    width: 45px;
    height: 45px;
  }
  &.xxl {
    width: 125px;
    height: 125px;
    border-radius: 62px;
  }
}
.avatar-block {
  width: 45px;
  height: 35px;
  margin-right: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  font-weight: 500;
  &.md {
    width: 45px;
    height: 45px;
  }
  &.xxl {
    width: 125px;
    height: 125px;
    margin-right: 0;
  }
}

.user-abbreviation {
  font-size: 13px;
  position: absolute;
  font-weight: 600;
  &.md {
    font-size: 15px;
  }
  &.xxl {
    font-size: 25px;
  }
}

.avatar-container {
  border-radius: "50%";
  width: 100;
  height: 100;
  border: "2px solid grey";
}

.img {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  &.md {
    height: 45px;
    width: 45px;
  }
}
