.call-controls-wrapper {
  .deactivated {
    opacity: 0.7;
    pointer-events: none;
  }
  display: flex;
  .controls-divider {
    height: 50px;
    margin: 0 10px;
    background-color: #D8DADD;
    width: 1px;
  }
  .button {
    font-size: 16px;
    .button-text {
      margin-left: 15px;
    }
    &:hover {
      background-color: unset;
    }
  }
  .end-call-button {
    color: #FF4545;
  }
  .call-buttons-wrapper {
    display: flex;
    .disabled-button {
      background-color: #FF4545;
      .button-icon {
        color: #FFFFFF;
      }
    }
    .screen-share-button {
      margin-left: 30px;
      margin-right: 15px;
    }
    button {
      margin-left: 15px;
      box-shadow: unset;
      border: 1px solid #D8DADD;
    }
    span {
      color: #253342
    }
  }
  .leave-icon {
    font-size: 18px;
  }
}

.video-space-wrapper-mobile {
  position: relative;

  .local-video {
    left: 10px;
    right: auto;
    top: 105px;
    object-fit: cover;
    object-position: center center;
    height: 170px;
    width: 100px;
  }

  .remote-video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .room-call-header {
    position: absolute;
    left: 0;
    top: 0;
    height: 95px;
    width: 100%;
    z-index: 3;
    overflow: hidden;

    &::before {
      position: absolute;
      background: #33475B;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
    }

    &.is-blurred::before {
      position: absolute;
      background: #253342;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
      opacity: 0.3;
    }

    .ti-arrow-left {
      position: absolute;
      bottom: 0;
      left: 0;
      color: white;
      padding: 10px
    }
  }

  .camera-disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #253342;
    z-index: 1;

    >div {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      margin-top: -80px;
      width: 100%;

      >img {
        display: block;
        margin: 20px auto;
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
      > span {
        margin: 0 5px;
        color: #888888;
        position: relative;
        top: 7px;
      }

      >b {
        color: white;
        margin-right: 10px;
      }
    }
  }

  .video-wrapper {
    height: 100%;
    width: 100%;
  }

  .incoming-call-chat {
    width: 100%;
    height: calc(100% - 82px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    overflow: hidden;

    .header, .header-title-wrap {
      width: 100%;
      padding: 10px;
    }

    .header {
      height: 55px;
    }

    .store-location {
      height: 30px;
    }

    .call-time-wrap {
      height: 65px;
    }

    .chat-body, .sender-wrap {
      margin: 0!important;
      width: 100%!important;
      overflow-x: hidden;

      .msg-form {
        width: 100%;
        input[type="text"] {
          width: 70%;
        }
      }
    }

    .chat-main-body {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - 150px)!important;

      .rct-scroll {
        height: calc(100% - 65px)!important;
      }

      .chat-footer {
        width: 100%;
        display: block;

        .sender-controls {
          width: 40px;
        }
        .submit-button {
          top: 37%;
        }
      }
    }
  }

  .main-container {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #253342;

    .video-container {
      height: 100%;
    }
  }

  .toolbar {
    background: transparent;
    border: 0!important;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    height: 82px;
    z-index: 2;

    &::before {
      position: absolute;
      background: #253342;
      width: 100%;
      height: 100%;
      content: " ";
      left: 0;
      top: 0;
      opacity: 0.3;
    }
  }

  .call-controls-wrapper {
    position: absolute;
    left: 0;
    top: 15px;
    width: 100%;
    text-align: center;
    display: block;

    button span {
      color: white;
    }

    .call-buttons-wrapper {
      display: inline-block;
    }

    .settings-button, .end-call-button {
      margin-left: 15px;
      box-shadow: unset;
      border: 1px solid #D8DADD;
    }

    .settings-button {
      margin: 0;
    }

    .end-call-button {
      border-color: #ea4141;

      span {
        color: #ea4141;
      }
    }

    .controls-divider {
      margin-right: 0;
      margin-left: 15px;
      display: inline-block;
      margin-bottom: -20px;
      background-color: #33475B;
      opacity: 0.6;
    }

  }
}
